const validationRules = {
  array: {
    haveAtLeastOne: 'Debe de seleccionar al menos un permiso',
    max: 'Debe tener como mucho {{max}} elementos',
    min: 'Debe tener al menos {{min}} elementos',
  },
  date: {
    max: 'La fecha máxima debe ser {{max}}',
    min: 'La fecha mínima debe ser {{min}}',
    wrongTime: 'Debe de usar el formato de hora HH:mm',
  },
  number: {
    integer: 'Debe ser un número entero',
    lessThan: 'Debe ser menor que {{less}}',
    max: 'El valor máximo es {{max}}',
    min: 'El valor mínimo es {{min}}',
    moreThan: 'Debe ser mayor que {{more}}',
    negative: 'Debe ser un número negativo',
    positive: 'Debe ser un número positivo',
  },
  required: 'Requerido',
  string: {
    alphanumeric: 'Debe ser alfanumérico',
    cif: 'El formato del CIF no es correcto',
    email: 'Debe ser un email válido',
    identifier: 'El formato del DNI no es correcto',
    ip: 'El formato de la dirección no es correcto',
    length: 'La longitud debe ser {{length}}',
    max: 'La longitud máxima debe ser {{min}} caracteres',
    min: 'La longitud mínima debe ser {{min}} caracteres',
    nie: 'El formato del NIE no es correcto',
    passport: 'El formato del pasaporte no es correcto',
  },
  typeError: 'Error de formato',
};
export default validationRules;
