export interface IBase64Data {
  mimetype: string;
  data: string;
}

export class Base64Data implements IBase64Data {
  constructor(data: IBase64Data) {
    this.mimetype = data.mimetype;
    this.data = data.data;
  }

  toDataUrl(): string {
    return `data:${this.mimetype};base64,${this.data}`;
  }

  toBlob(): string {
    const byteCharacters = atob(this.data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: this.mimetype });
    return URL.createObjectURL(blob);
  }

  mimetype: string;

  data: string;
}
