import {
  Formik, Form, FormikProps, FormikHelpers,
} from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import logo from 'assets/images/logo_funus.svg';

import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import Message from 'components/base/Message';

import { LoginError } from 'config/apiErrors';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { LoginFormType } from 'models/User';

import './index.scss';

const Login: FC = () => {
  const [loginError, setLoginError] = useState<string | undefined>();
  const { setUser } = useProvidedAuth();
  const { t } = useTranslation();

  const onSubmit = (
    values: LoginFormType,
    actions: FormikHelpers<LoginFormType>,
  ) => {
    config.apiFunus.login
      .login(values)
      .then((res) => {
        if (res?.data) {
          config.storage.setToken(res.data.token);
          setUser(res.data, () => actions.setSubmitting(false));
        }
        return res;
      })
      .catch((error) => {
        if (error instanceof LoginError) {
          setLoginError(t(error.message));
        } else if (error?.response?.status) {
          setLoginError(t('error.login.generic'));
        } else {
          setLoginError(t('error.login.request'));
        }
        actions.setSubmitting(false);
      });
  };

  return (
    <div id="login-page">
      <div>
        <img alt="Funus" src={logo} />
        <Formik
          initialValues={{
            password: '',
            username: '',
          }}
          validate={(values: LoginFormType) => config.validator(values, 'login')}
          validateOnChange={false}
          validateOnBlur
          onSubmit={onSubmit}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }: FormikProps<LoginFormType>) => (
            <Form autoComplete="off">
              {isSubmitting && <Loader />}
              <h2>{t('user.access')}</h2>
              <Input
                data-testid="username"
                error={touched.username && errors.username}
                name="username"
                placeholder={t('user.username')}
                value={values.username}
                onBlur={handleBlur}
                onChange={(val: string) => {
                  setFieldValue('username', val?.toLowerCase());
                  setLoginError(undefined);
                }}
              />
              <Input
                data-testid="password"
                error={touched.password && errors.password}
                name="password"
                placeholder={t('user.password')}
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={(val: string) => {
                  setFieldValue('password', val);
                  setLoginError(undefined);
                }}
              />
              <Button
                className="forgot-button"
                color="transparent"
                text={t('user.forgotPassword')}
                to={config.url.forgot}
              />
              {loginError && <Message type="error">{loginError}</Message>}
              <Button
                color="special"
                disabled={isSubmitting}
                text={t('user.login')}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
