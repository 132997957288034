import article from 'i18n/esp/article';
import budget from 'i18n/esp/budget';
import common from 'i18n/esp/common';
import enquiry from 'i18n/esp/enquiry';
import error from 'i18n/esp/error';
import information from 'i18n/esp/information';
import ip from 'i18n/esp/ip';
import menu from 'i18n/esp/menu';
import order from 'i18n/esp/order';
import qr from 'i18n/esp/qr';
import record from 'i18n/esp/record';
import role from 'i18n/esp/role';
import sale from 'i18n/esp/sale';
import service from 'i18n/esp/service';
import user from 'i18n/esp/user';
import validationRules from 'i18n/esp/validationRules';

export default {
  article,
  budget,
  common,
  enquiry,
  error,
  information,
  ip,
  menu,
  order,
  qr,
  record,
  role,
  sale,
  service,
  user,
  validationRules,
};
