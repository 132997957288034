import lodash from 'lodash';

export const capitalizeString = (str: string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

type FormatNameParams = {
  name?: string;
  firstSurname?: string;
  secondSurname?: string;
};

export const formatName = ({
  name,
  firstSurname,
  secondSurname,
}: FormatNameParams): string => {
  let fullName = '';
  if (firstSurname) {
    fullName += firstSurname;
  }
  if (secondSurname) {
    fullName += `${firstSurname ? ' ' : ''}${secondSurname}`;
  }
  if ((firstSurname || secondSurname) && name) {
    fullName += `, ${name}`;
  } else if (name) {
    fullName += name;
  }

  return fullName;
};

export const objectsAreDifferent = <T>(object1: T, object2: T): boolean => {
  const res = !lodash.isEqual(object1, object2);
  return res;
};

export const isBoolean = (v: unknown):boolean => lodash.isBoolean(v);
export const isBooleanAndTrue = (v: boolean | undefined | null):boolean => lodash.isBoolean(v) && v;
export const isBooleanAndFalse = (
  v: boolean | undefined | null,
):boolean => lodash.isBoolean(v) && !v;

export const isNull = (data: unknown): boolean => lodash.isNull(data);
export const isNumber = (data: unknown): boolean => lodash.isNumber(data);
export const toString = (a: unknown): string => String(a);
