import { FormikProps, getIn } from 'formik';
import { isEmpty } from 'lodash';
import React, {
  useState, useMemo, FC, useEffect, Fragment,
} from 'react';
import { CheckCircle, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast } from 'utils/toasts';
import { getIdentifier, identifiersValidator } from 'utils/userUtils';

import BankAccount from 'components/base/BankAccount';
import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import IdentifierSelector from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import LanguageSelector from 'components/base/LanguageSelector';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Table from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';
import Textarea from 'components/base/Textarea';

import Client from 'models/Client';
import { CityType } from 'models/MasterData';
import { SaveService } from 'models/MasterService';

import './index.scss';

type ClientsProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
};

const initialClient: Client = {
  address: {},
  bankAccount: {},
  cif: '',
  comment: '',
  email: '',
  identifier: '',
  name: '',
  nie: '',
  passport: '',
  paymentTerm: '',
  phone: '',
  surname: '',
};

const Clients: FC<ClientsProps> = ({
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const [disableClient, setDisableClient] = useState(true);
  const [error, setError] = useState(false);
  const [validIdentifier, setValidIdentifier] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<Client>(initialClient);
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);

  const { t } = useTranslation();

  const enquiriesCols = useMemo(
    (): TableColumn<Client>[] => [
      {
        accessor: 'identifier',
        Cell: ({ row: { original } }) => getIdentifier(original),
        Header: `${t('common.identifierDocument')}`,
      },
      {
        accessor: 'name',
        Cell: ({ row: { original } }) => original.name || '',
        className: 'primary-dark-color',
        Header: `${t('common.name')}`,
      },
      { accessor: 'email', Header: `${t('common.email')}` },
      {
        accessor: 'phone',
        Header: `${t('common.phone')}`,
      },
    ],
    [t],
  );

  const setEditing = (row: Client, index: number) => {
    if (values.clients?.length) {
      setSelectedIdx(index);
      setSelected({ ...row });
    }
  };

  const removeItem = (row: Client, index: number) => {
    const newClients = [...(values.clients || [])];
    newClients.splice(index, 1);
    setFieldValue('clients', newClients);
  };

  const onChange = (param: string, value?: string) => {
    setSelected((sel) => ({ ...sel, [param]: value || '' }));
  };

  const getError = (value: string): boolean => values.clients?.some(
    (cli) => getIdentifier(cli) === value,
  ) || false;

  const onChangeIdentifier = (param: string, value: string) => {
    setSelected((sel) => ({ ...sel, [param]: value || '' }));
    const err = getError(value);
    const valid = identifiersValidator(param, value);
    setError(err && selectedIdx < 0);
    setValidIdentifier(valid);
    if (!err && valid && selectedIdx === -1) {
      setLoading(true);
      config.apiFunus.adviser
        .searchClient({ [param]: value })
        .then((res) => {
          if (res.data?.name) {
            setSelected({ ...res.data, [param]: value || '' });
          }
          setLoading(false);
          return res;
        })
        .catch((responseError) => {
          showErrorToast(responseError.message);
          setLoading(false);
        });
    } else {
      setSelected({ ...initialClient, [param]: value });
    }
  };

  const onChangeAddress = (param: string, value?: string) => {
    setSelected((sel) => ({
      ...sel,
      address: { ...sel.address, [param]: value || '' },
    }));
  };

  const onChangeBank = (param: string, value?: string) => {
    setSelected((sel) => ({
      ...sel,
      bankAccount: { ...sel.bankAccount, [param]: value || '' },
    }));
  };

  const onClear = () => {
    setSelected(initialClient);
    setSelectedIdx(-1);
    setValidIdentifier(false);
    setError(false);
  };

  const onSave = () => {
    const newClients = [...(values.clients || [])];
    if (selectedIdx === -1) {
      newClients.push(selected);
    } else {
      newClients[selectedIdx] = selected;
    }
    setFieldValue('clients', newClients);
    onClear();
  };

  const getActions = (row: Client, index: number) => {
    const clientActions = {
      edit: {
        icon: <CustomIcon icon={FeatherIconTypes.EDIT} />,
        onClick: () => setEditing(row, index),
        tooltipCaption: t('common.edit'),
      },
      remove: {
        icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
        onClick: () => removeItem(row, index),
        tooltipCaption: t('common.remove'),
      },
    };
    return clientActions;
  };

  useEffect(() => {
    setDisableClient(
      !Object.values(selected).some((val) => {
        if (typeof val === 'object') {
          return !isEmpty(val);
        }
        return !!val;
      }),
    );
  }, [selected]);

  const isDisabled = disabled || isSubmitting || (values.clients || []).length > 4;

  return (
    <fieldset className="client-expandable-box">
      {!disabled && (
        <Fragment>
          <IdentifierSelector
            disabled={isDisabled || selectedIdx > -1}
            errors={{
              cif: error ? `${t('service.duplicatedClient')}` : undefined,
              identifier: error
                ? `${t('service.duplicatedClient')}`
                : undefined,
              nie: error ? `${t('service.duplicatedClient')}` : undefined,
              passport: error ? `${t('service.duplicatedClient')}` : undefined,
            }}
            name="clients.identifier"
            placeholder={t('common.identifierDocument')}
            values={{
              cif: selected.cif,
              identifier: selected.identifier,
              nie: selected.nie,
              passport: selected.passport,
            }}
            onBlur={handleBlur}
            onChange={onChangeIdentifier}
          />
          <Input
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            // error={formErrors.name}
            name="clients.name"
            placeholder={t('common.fullName')}
            type="text"
            value={selected.name}
            onBlur={handleBlur}
            onChange={(val) => onChange('name', `${val}`)}
          />
          <Input
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            // error={formErrors.email}
            name="clients.email"
            placeholder={t('common.email')}
            type="text"
            value={selected.email}
            onBlur={handleBlur}
            onChange={(val) => onChange('email', `${val}`)}
          />
          <Input
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            // error={formErrors.phone}
            name="clients.phone"
            placeholder={t('common.phone')}
            type="text"
            value={selected.phone}
            onBlur={handleBlur}
            onChange={(val) => onChange('phone', `${val}`)}
          />
          <SelectCountryProvinceCity
            key="address"
            cityProps={{
              disabled:
                isDisabled
                || loading
                || (!validIdentifier && selectedIdx === -1),
              error: getIn(errors, 'address.city'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'address.city',
              onBlur: handleBlur,
              onChange: (val: string) => onChangeAddress('city', val),
              onChangeFull: (val?: CityType) => onChangeAddress('postalCode', val?.postalCode),
              placeholder: t('common.city'),
              value: selected?.address?.city || '',
            }}
            countryProps={{
              disabled:
                isDisabled
                || loading
                || (!validIdentifier && selectedIdx === -1),
              error: getIn(errors, 'address.country'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'address.country',
              onBlur: handleBlur,
              onChange: (val: string) => onChangeAddress('country', val),
              placeholder: t('common.country'),
              value: selected?.address?.country || '',
            }}
            initialize={false}
            provinceProps={{
              disabled:
                isDisabled
                || loading
                || (!validIdentifier && selectedIdx === -1),
              error: getIn(errors, 'address.province'),
              getLabel: ({ description }) => description,
              getValue: ({ code }) => code,
              name: 'address.province',
              onBlur: handleBlur,
              onChange: (val: string) => onChangeAddress('province', val),
              placeholder: t('common.province'),
              value: selected?.address?.province || '',
            }}
          />
          <Textarea
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            error={getIn(errors, 'streetName')}
            name="address.streetName"
            placeholder={t('common.domicile')}
            value={selected?.address?.streetName || ''}
            onBlur={handleBlur}
            onChange={(val: string) => onChangeAddress('streetName', val)}
          />
          <Input
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            error={getIn(errors, 'address.postalCode')}
            name="address.postalCode"
            placeholder={t('common.postalCode')}
            value={selected?.address?.postalCode || ''}
            onBlur={handleBlur}
            onChange={(val: string) => onChangeAddress('postalCode', val)}
          />
          <LanguageSelector
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            error={getIn(errors, 'primaryLanguage')}
            name="primaryLanguage"
            placeholder={t('record.clients.primaryLanguage')}
            value={selected?.primaryLanguage}
            onBlur={handleBlur}
            onChange={(val) => onChange('primaryLanguage', val?.value)}
          />
          <LanguageSelector
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            error={getIn(errors, 'secondaryLanguage')}
            name="secondaryLanguage"
            placeholder={t('record.clients.secondaryLanguage')}
            value={selected?.secondaryLanguage}
            onBlur={handleBlur}
            onChange={(val) => onChange('secondaryLanguage', val?.value)}
          />
          <Input
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            // error={formErrors.paymentTerm}
            name="clients.paymentTerm"
            placeholder={t('record.clients.paymentTerm')}
            value={selected.paymentTerm}
            onBlur={handleBlur}
            onChange={(val: string) => onChange('paymentTerm', val)}
          />
          <BankAccount
            disabled={
              isDisabled || loading || (!validIdentifier && selectedIdx === -1)
            }
            error={getIn(errors, 'bankAccount')}
            name="bank"
            value={selected?.bankAccount}
            onChange={(param, val) => onChangeBank(param, val)}
          />
        </Fragment>
      )}
      {!disabled && (
        <div className="declarants-buttons">
          <Button
            disabled={
              disableClient
              || isDisabled
              || (error && selectedIdx < 0)
              || (!validIdentifier && selectedIdx < 0)
            }
            leftAddon={<CheckCircle />}
            text={t('common.add')}
            onClick={onSave}
          />
          <Button
            color="secondary"
            disabled={disableClient || isDisabled}
            leftAddon={<X />}
            text={t('common.clear')}
            onClick={onClear}
          />
        </div>
      )}
      <Table<Client>
        actions={disabled ? undefined : getActions}
        columns={enquiriesCols}
        data={values.clients || []}
        pagination={false}
      />
    </fieldset>
  );
};

export default Clients;
