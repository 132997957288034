import { ReceptionTypes } from 'hooks/useReceptionTypes';
import Address from 'models/Address';
import BaseModel from 'models/BaseModel';
import { IMasterDataDto } from 'models/MasterData';
import { SimpleUser } from 'models/User';

export enum LandingRecords {
  ASSIGNED = 'ASSIGNED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  ASSIGNED_OTHERS = 'ASSIGNED_OTHERS',
}

export enum RecordStateEnum {
  FINISHED = 'FINISHED',
  INVOICED = 'INVOICED',
  INVOICED_PENDING = 'INVOICED_PENDING',
  OPENED = 'OPENED',
}

export enum RecordTypeEnum {
  ABORTION = '2',
  AMPUTATION = '3',
  DEATH = '1',
}

export type AbortionData = {
  comment?: string;
  declarantIsMother?: boolean;
  fatherFirstSurname?: string;
  fatherName?: string;
  fatherSecondSurname?: string;
  gender?: string;
  gestationWeeks?: number;
  type?: string;
};

export type AmputationData = {
  member?: string;
};

export type DeceasedData = {
  age?: string;
  birthAddress?: Address;
  birthday?: Date;
  childrenNames?: string;
  deceasedAddress?: Address;
  fatherName?: string;
  firstSurname?: string;
  fullName?: string;
  gender?: string;
  identifier?: string;
  maritalStatus?: string;
  motherName?: string;
  name?: string;
  nationality?: string;
  nickname?: string;
  nie?: string;
  partnerName?: string;
  passport?: string;
  phone?: string;
  recordNumber?: number;
  secondSurname?: string;
  personalObjects?: string;
  coffinSize?: string;
  defunctStatus?: string;
};

export enum DeathType {
  natural = 'N',
  judicial = 'J',
}

export type DeathData = {
  address?: Address;
  approximatedDate?: string;
  atHome?: boolean;
  comment?: string;
  deathCause?: string;
  deathDate?: Date;
  deathTime?: Date;
  deathType?: DeathType;
  destinationCenter?: string;
  diseases?: string;
  doctor?: string;
  errands?: string;
  location?: string;
  medicalCertificate?: boolean;
  pickupAddress?: Address;
  trialCourt?: string;
};

export type Declarant = {
  address?: Address;
  cif?: string;
  comment?: string;
  deceasedKinship?: string;
  email?: string;
  firstSurname?: string;
  fullName?: string;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  secondSurname?: string;
  signature?: string;
};

export type Document = {
  id: string;
  name: string;
  type: string;
};

export type LocalData = {
  description: string;
  localId: string;
};

export type InsuranceCompany = IMasterDataDto & {
  locals: LocalData[];
};

export type Insurance = {
  agent?: string;
  amount?: string;
  companyDelegation?: string;
  companyName?: string;
  hasPolicy?: boolean;
  idPaymentLocation?: string;
  policyNumber?: string;
  sinister?: string;
  renounce?: boolean;
  maxCoverage?: number;
};

export type ReceptionData = {
  receptionType: ReceptionTypes;
  entryDate: Date;
  entryTime: Date;
  funeraryOrigin: string;
  observations: string;
  operatorComment: string;
};

export type SaveRecord = {
  creator?: {
    name: string;
    firstSurname: string;
  };
  abortionData?: AbortionData;
  ageConfidential?: boolean;
  amputationData?: AmputationData;
  center?: string;
  comment?: string;
  collectionComment?: string;
  createdDate?: string;
  deathData?: DeathData;
  deceasedData?: DeceasedData;
  declarantData?: Declarant;
  documents?: Document[];
  erpId?: string | number | undefined;
  hasPickUp?: boolean;
  canCreateReception?: boolean;
  hasReception?: boolean;
  hasWorkOrder?: boolean;
  id?: string;
  insuranceData?: Insurance;
  negotiators?: SimpleUser[];
  // preneed?: boolean;
  publishCeremony?: boolean;
  publishVigil?: boolean;
  sendByEmail?: boolean;
  signature?: string;
  state: RecordStateEnum;
  type?: RecordTypeEnum;
  appointmentDateTime?: string;
  receptionData?: ReceptionData
  appointmentDate?: string;
  appointmentTime?: string;
  canCreateBudget?: boolean;
};

export type SearchRecordProps = {
  comment?: string | undefined;
  createdDate?: string | undefined;
  date?: string | undefined;
  deceased?: {
    firstSurname?: string | undefined;
    name?: string | undefined;
    secondSurname?: string | undefined;
  };
  declarant?: {
    fullName?: string | undefined;
  };
  id?: string | undefined;
  remarks?: string | undefined;
  state?: RecordStateEnum | undefined;
  type?: IMasterDataDto | string | undefined;
  user?: IMasterDataDto | string | undefined;
};

type Record = BaseModel & {
  ageConfidential?: boolean;
  center?: string;
  comment?: string;
  deathData?: DeathData;
  deceasedData?: DeceasedData;
  declarantData?: Declarant;
  documents?: Document[];
  erpId?: string | number | undefined;
  insuranceData?: Insurance;
  negotiators?: SimpleUser[];
  // 'preneed'?: boolean;
  publishCeremony?: boolean;
  publishVigil?: boolean;
  sendByEmail?: boolean;
  state: RecordStateEnum;
  type?: string;
  appointmentDateTime?: string;
  canDelete?: boolean;
  // workorders?: Order[];
  // FIXME: Remove this fields when we can handle sorting in table
  'deceased.name'?: string;
  'death.deathDate'?: string;
  'deceased.deceasedAddress'?: string;
  ashesDelivery?: boolean;
  hasCommercial?: boolean;
};

export default Record;
