const error = {
  500: 'No se puede conectar con el servidor en estos momentos',
  concurrency: 'Los datos ya han sido modificados por un altre usuari, es necessari recarregar la pàgina.',
  deceasedExist: 'Ya existe un expediente para un fallecido con dicho documento identificativo',
  emailFormat: 'Debe ser un email válido',
  fallbackError: {
    dashboard: 'escritorio',
    error: 'Error',
    go: 'Ir a',
    stacktrace: 'Más información',
    title: 'ERROR',
  },
  forbidden: 'Revise permisos del usuario',
  forgot: 'Se ha producido un error. Por favor, asegurese que el nombre de usuario es correcto y vuelva a intentarlo',
  incineration: {
    authorization: 'No se puede proceder con la incineración debido a que NO SE DISPONE DE TODA LA DOCUMENTACIÓN PARA INCINERAR',
  },
  invalidToken: 'El token es inválido, por favor, solicite de nuevo el cambio de contraseña.',
  login: {
    generic: 'Algo ha ido mal',
    request: 'Error de conexión',
    userPass: 'Usuario o contraseña incorrecto',
  },
  minLength: 'La longitud mínima es',
  passwordMatch: 'Las contraseñas deben coincidir',
  required: 'Requerido',
};
export default error;
