import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProvinces } from 'actions/masterData';

import { ProvinceType } from 'models/MasterData';
import { RootState } from 'store';

export interface ProvincesHook {
  provinces: ProvinceType[],
  getProvinceByNormalizedCode: (
    provinceCode: string | undefined,
    countryCode: string | undefined) => ProvinceType | undefined
}

export const useProvinces = (): ProvincesHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.provinces,
      normalized: state.normalized.provinces,
    }),
  );

  const getProvinceByNormalizedCode = useCallback(
    (provinceCode: string | undefined, countryCode: string | undefined) => {
      const normalizedProvinceCode = `${countryCode}${provinceCode}`;
      return provinceCode && countryCode
      && normalized[normalizedProvinceCode]
        ? items.find(
          (province:
            ProvinceType) => province.code === provinceCode && province.countryCode === countryCode,
        )
        : undefined;
    },
    [normalized, items],
  );

  useEffect(() => {
    dispatch(getProvinces());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getProvinceByNormalizedCode,
    provinces: items || [],
  };
};
