import { FormikProps } from 'formik';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Download, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import Input from 'components/base/Input';
import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';
import Table from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';

import { Document, SaveRecord } from 'models/Record';

type DocumentFieldsProps = {
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};
/** {
  disabled,
  formikProps: { errors, handleBlur, isSubmitting, setFieldValue, values },
} */
const DocumentsFields: FC<DocumentFieldsProps> = ({
  disabled,
  formikProps,
}) => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Document>();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const documentsCols = useMemo(
    (): TableColumn<Document>[] => [
      {
        accessor: 'id',
        Header: 'Id',
        hidden: true,
      },
      {
        accessor: 'name',
        className: 'primary-dark-color',
        Header: `${t('common.name')}`,
      },
      {
        accessor: 'type',
        Cell: ({ cell: { value } }) => value || '',
        Header: `${t('common.type')}`,
      },
    ],
    [t],
  );

  const hide = () => {
    setFile(undefined);
    setShowModal(false);
  };

  const closeDelete = () => {
    setSelectedFile(undefined);
  };

  const uploadFile = async () => {
    if (file && formikProps.values.erpId) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file, fileName);
      try {
        const res = await config.apiFunus.documents.upload(
          formikProps.values.erpId,
          formData,
        );
        setLoading(false);
        showSuccessToast(t('record.document.uploadOk'));

        const newDocs = [
          ...(formikProps.values.documents || []),
          { ...res.data.entry, type: res.data.entry.content.mimeType },
        ];
        formikProps.setFieldValue('documents', newDocs);

        hide();
      } catch {
        setLoading(false);
        showErrorToast(t('record.document.uploadKo'));
      }
    }
  };

  const deleteFile = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        await config.apiFunus.documents.remove(selectedFile.id);
        showSuccessToast(t('record.document.removeOk'));
        const newDocs = [...(formikProps.values.documents || [])];
        const idx = newDocs.findIndex((doc) => doc.id === selectedFile.id);
        newDocs.splice(idx, 1);
        formikProps.setFieldValue('documents', newDocs);
        setLoading(false);
        closeDelete();
      } catch {
        showErrorToast(t('record.document.removeKo'));
        setLoading(false);
        closeDelete();
      }
    }
  };

  const downloadFile = async (row: Document) => {
    setLoading(true);
    try {
      const res = await config.apiFunus.documents.download(row.id);
      // DOWNLOAD AS BASE64
      const tempLink = document.createElement('a');
      tempLink.href = `data:${row.type};base64,${res.data}`;
      tempLink.download = row.name;
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFileName(file?.name || '');
  }, [file]);

  return (
    <fieldset>
      {formikProps.values.erpId && (
        <div className="documents-buttons">
          <Button
            disabled={disabled}
            leftAddon={<PlusCircle />}
            text={`${t('common.add')} ${t('common.document')}`}
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
      <Modal
        buttons={[
          {
            id: 'accept',
            onClick: deleteFile,
            text: t('common.accept'),
          },
        ]}
        show={!!selectedFile}
        title={`${t('common.remove')} ${t('common.document')}`}
        onHide={closeDelete}
      >
        <p>
          {t('record.document.sure')}
          {' '}
          <strong>{selectedFile?.name}</strong>
        </p>
        <p>{t('common.sure')}</p>
      </Modal>
      <Modal
        buttons={[
          {
            disabled: !file,
            id: 'accept',
            onClick: uploadFile,
            text: t('common.accept'),
          },
        ]}
        show={showModal}
        title={`${t('common.add')} ${t('common.document')}`}
        onHide={hide}
      >
        {loading && <Loader />}
        <Input
          name="document"
          placeholder={t('common.selectFile')}
          type="file"
          value={file?.name || ''}
          onChange={(val: File) => setFile(val)}
        />
        <Input
          disabled={!file}
          name="fileName"
          placeholder={t('common.name')}
          value={fileName || ''}
          onChange={(val: string) => setFileName(val)}
        />
      </Modal>
      <Table<Document>
        actions={(row: Document) => ({
          download: {
            icon: <Download />,
            onClick: () => downloadFile(row),
          },
          extra: [],
          remove: disabled
            ? undefined
            : {
              icon: <CustomIcon icon={FeatherIconTypes.TRASH} />,
              onClick: () => setSelectedFile(row),
              tooltipCaption: t('common.remove'),
            },
        })}
        columns={documentsCols}
        data={formikProps.values.documents || []}
        pagination={false}
      />
    </fieldset>
  );
};

export default DocumentsFields;
