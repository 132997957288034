import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { addDays } from 'utils/dateManager';

import Datepicker from 'components/base/Datepicker';
import IdentifierSelector from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import RadioButton from 'components/base/RadioButton';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Sign from 'components/base/Sign';
import Textarea from 'components/base/Textarea';

import { MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import useSanitaryPracticeLocation from 'hooks/useSanitaryPracticeLocation';
import { i18n } from 'i18n';
import { CityType, IMasterDataDto } from 'models/MasterData';
import {
  CoffinTypes,
  DeceasedLocation,
  FinalDestination,
  SaveService,
} from 'models/MasterService';
import { DeathType, SaveRecord } from 'models/Record';
import { RootState } from 'store';

import './index.scss';

type PreparationProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};
const preparationOptions = [
  { label: i18n.t('common.yes'), value: 'yes' },
  { label: i18n.t('common.no'), value: 'no' },
];

const PreparationForm: FC<PreparationProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const { sanitaryPractices, thanatologists, thanatopractors } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const {
    sanitaryPracticeLocations,
    normalizedSanitaryPracticeLocations,
  } = useSanitaryPracticeLocation(
    values.preparationServiceDto?.sanitaryPractices?.[0]?.address?.city,
  );
  const [hasPractice, setHasPractice] = useState(false);
  const [selector, setSelector] = useState<string>();

  const { t } = useTranslation();

  const sanitaryAtHomeOnChange = (val: boolean) => {
    let addressCopied = {
      ...values.preparationServiceDto?.sanitaryPractices?.[0]?.address,
    };
    if (val && record?.deathData?.pickupAddress) {
      addressCopied = {
        city: record.deathData.pickupAddress?.city || addressCopied.city,
        country:
          record.deathData.pickupAddress?.country || addressCopied.country,
        province:
          record.deathData.pickupAddress?.province || addressCopied.province,
        streetName:
          record.deathData.pickupAddress?.streetName
          || addressCopied.streetName,
      };
    }

    setFieldValue('preparationServiceDto.sanitaryPractices[0].atHome', val);
    setFieldValue(
      'preparationServiceDto.sanitaryPractices[0].location',
      undefined,
    );
    setFieldValue(
      'preparationServiceDto.sanitaryPractices[0].address',
      val
        ? addressCopied
        : {
          ...(values.preparationServiceDto?.sanitaryPractices?.[0]?.address
              || {}),
          streetName: undefined,
        },
    );
  };

  const getPreparationOptions = (val: string) => {
    if (val === preparationOptions[0].value) {
      return true;
    }
    if (val === preparationOptions[1].value) {
      return false;
    }
    return undefined;
  };

  const setAconditioning = (val: string) => {
    const aconditioning = getPreparationOptions(val);
    setFieldValue('preparationServiceDto.aconditioning', aconditioning);
    if (!aconditioning) {
      setFieldValue('preparationServiceDto.coffin', false);
    }
  };

  const setDeclarantData = (checkBoxValue: boolean) => {
    setFieldValue('preparationServiceDto.applicant.declarant', checkBoxValue);

    if (checkBoxValue) {
      if (values.declarantData?.passport) {
        setSelector('passport');
      }
      if (values.declarantData?.cif) {
        setSelector('cif');
      }
      if (values.declarantData?.nie) {
        setSelector('nie');
      }
      if (values.declarantData?.identifier) {
        setSelector('identifier');
      }
    } else {
      setSelector(undefined);
    }

    setFieldValue(
      'preparationServiceDto.applicant.name',
      checkBoxValue ? values.declarantData?.fullName : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.passport',
      checkBoxValue ? values.declarantData?.passport : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.cif',
      checkBoxValue ? values.declarantData?.cif : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.identifier',
      checkBoxValue ? values.declarantData?.identifier : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.nie',
      checkBoxValue ? values.declarantData?.nie : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.address.streetName',
      checkBoxValue ? values.declarantData?.address?.streetName : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.address.postalCode',
      checkBoxValue ? values.declarantData?.address?.postalCode : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.address.province',
      checkBoxValue ? values.declarantData?.address?.province : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.address.city',
      checkBoxValue ? values.declarantData?.address?.city : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.address.country',
      checkBoxValue ? values.declarantData?.address?.country : '',
    );
    setFieldValue(
      'preparationServiceDto.applicant.phone',
      checkBoxValue ? values.declarantData?.phone : '',
    );
    setFieldValue('preparationServiceDto.applicant.signature', undefined);
  };

  useEffect(() => {
    setHasPractice(
      values.preparationServiceDto?.sanitaryPractices?.[0]
        ? Object.values(
          values.preparationServiceDto.sanitaryPractices[0],
        ).filter((val) => !!val).length > 0
        : false,
    );

    if (
      values.preparationServiceDto?.sanitaryPractices?.[0]
      && !values.preparationServiceDto?.sanitaryPractices?.[0]?.startDate
      && record?.deathData?.deathType === DeathType.natural
      && record.deathData.deathDate
    ) {
      setFieldValue(
        'preparationServiceDto.sanitaryPractices[0].startDate',
        addDays(record.deathData.deathDate, 1),
      );
      setFieldValue(
        'preparationServiceDto.sanitaryPractices[0].startTime',
        record.deathData.deathTime,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.preparationServiceDto]);

  const isChecked = values.preparationServiceDto?.applicant?.declarant;

  return (
    <div className={classNames('preparation-form', className)}>
      <fieldset>
        <legend>{t(`service.${MasterServiceDtoType.PREPARACION}`)}</legend>
        <RadioButton
          checked={
            values.preparationServiceDto?.aconditioning === null
            || values.preparationServiceDto?.aconditioning === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.aconditioning
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.aconditioning')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.aconditioning"
          options={preparationOptions}
          placeholder={t('service.preparation.aconditioning')}
          onChange={setAconditioning}
        />
        <Input
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.personalItems')}
          name="preparationServiceDto.personalItems"
          placeholder={t('service.preparation.personalObjects')}
          type="text"
          value={values.preparationServiceDto?.personalItems || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.personalItems', val)}
        />
        <RadioButton<DeceasedLocation>
          checked={values.preparationServiceDto?.deceasedLocation}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.deceasedLocation')}
          getLabel={(el) => t(`service.deceasedLocation.${el}`)}
          getValue={(el) => el}
          name="preparationServiceDto.deceasedLocation"
          options={Object.values(DeceasedLocation)}
          placeholder={t('service.preparation.deceasedLocation')}
          onChange={(val) => setFieldValue('preparationServiceDto.deceasedLocation', val)}
        />
        <RadioButton<FinalDestination>
          checked={values.preparationServiceDto?.finalDestination}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.finalDestination')}
          getLabel={(el) => t(`service.finalDestination.${el}`)}
          getValue={(el) => el}
          name="preparationServiceDto.finalDestination"
          options={Object.values(FinalDestination)}
          placeholder={t('service.preparation.finalDestination')}
          onChange={(val) => setFieldValue('preparationServiceDto.finalDestination', val)}
        />
        <RadioButton<string>
          checked={
            values.preparationServiceDto?.coffin === undefined
            || values.preparationServiceDto?.coffin === null
              ? undefined
              : `${
                values.preparationServiceDto?.coffin
                  ? CoffinTypes.opened
                  : CoffinTypes.closed
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.coffin')}
          getLabel={(el) => t(`service.coffinType.${el}`)}
          getValue={(el) => el}
          name="preparationServiceDto.coffin"
          options={Object.values(CoffinTypes)}
          placeholder={t('service.preparation.coffin')}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.coffin',
            val === CoffinTypes.opened,
          )}
        />
        <Input
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.coffinPreparationDetail')}
          name="preparationServiceDto.coffinPreparationDetail"
          placeholder={t('service.preparation.coffinPreparationDetail')}
          type="text"
          value={values.preparationServiceDto?.coffinPreparationDetail || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.coffinPreparationDetail', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.creu === null
            || values.preparationServiceDto?.creu === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.creu
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.creu')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          id="preparationServiceDto.creu"
          name="preparationServiceDto.creu"
          options={preparationOptions}
          placeholder={t('service.preparation.creu')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.creu',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          aria-label="preparationServiceDto.creuComment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.creuComment')}
          name="preparationServiceDto.creuComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.creu',
          )}`}
          value={values.preparationServiceDto?.creuComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.creuComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.shaved === null
            || values.preparationServiceDto?.shaved === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.shaved
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.shaved')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.shaved"
          options={preparationOptions}
          placeholder={t('service.preparation.shaved')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.shaved',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          aria-label="preparationServiceDto.shavedComment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.shavedComment')}
          name="preparationServiceDto.shavedComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.shaved',
          )}`}
          value={values.preparationServiceDto?.shavedComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.shavedComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.clothing === null
            || values.preparationServiceDto?.clothing === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.clothing
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.clothing')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.clothing"
          options={preparationOptions}
          placeholder={t('service.preparation.clothing')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.clothing',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.clothingComment')}
          name="clothingComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.clothing',
          )}`}
          value={values.preparationServiceDto?.clothingComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.clothingComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.cover === null
            || values.preparationServiceDto?.cover === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.cover
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.cover')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.cover"
          options={preparationOptions}
          placeholder={t('service.preparation.cover')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.cover',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          aria-label="preparationServiceDto.coverComment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.coverComment')}
          name="preparationServiceDto.coverComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.cover',
          )}`}
          value={values.preparationServiceDto?.coverComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.coverComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.hairstyle === undefined
            || values.preparationServiceDto?.hairstyle === null
              ? ''
              : `${
                (values.preparationServiceDto?.hairstyle
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.hairstyle')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.hairstyle"
          options={preparationOptions}
          placeholder={t('service.preparation.hairstyle')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.hairstyle',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.hairstyleComment')}
          name="hairstyleComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.hairstyle',
          )}`}
          value={values.preparationServiceDto?.hairstyleComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.hairstyleComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.makeup === null
            || values.preparationServiceDto?.makeup === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.makeup
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.makeup')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.makeup"
          options={preparationOptions}
          placeholder={t('service.preparation.makeup')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.makeup',
            getPreparationOptions(val),
          )}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.makeupComment')}
          name="makeupComment"
          placeholder={`${t('common.comment')} ${t(
            'service.preparation.makeup',
          )}`}
          value={values.preparationServiceDto?.makeupComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.makeupComment', val)}
        />
        <RadioButton
          checked={
            values.preparationServiceDto?.pacemaker === null
            || values.preparationServiceDto?.pacemaker === undefined
              ? ''
              : `${
                (values.preparationServiceDto?.pacemaker
                  ? preparationOptions[0]
                  : preparationOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.pacemaker')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="preparationServiceDto.pacemaker"
          options={preparationOptions}
          placeholder={t('service.preparation.pacemaker')}
          onChange={(val: string) => setFieldValue(
            'preparationServiceDto.pacemaker',
            getPreparationOptions(val),
          )}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.preparation.sanitaryPractice')}</legend>
        <SelectCountryProvinceCity
          cityProps={{
            error: getIn(
              errors,
              'preparationServiceDto.sanitaryPractices[0].address.city',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.sanitaryPractices.address.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'preparationServiceDto.sanitaryPractices[0].address.city',
              val,
            ),
            placeholder: t('common.city'),
            value:
              values.preparationServiceDto?.sanitaryPractices?.[0]?.address
                ?.city || '',
          }}
          countryProps={{
            error: getIn(
              errors,
              'preparationServiceDto.sanitaryPractices[0].address.country',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.sanitaryPractices.address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'preparationServiceDto.sanitaryPractices[0].address.country',
              val,
            ),
            placeholder: t('common.country'),
            value:
              values.preparationServiceDto?.sanitaryPractices?.[0]?.address
                ?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(
              errors,
              'preparationServiceDto.sanitaryPractices[0].address.province',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.sanitaryPractices.address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'preparationServiceDto.sanitaryPractices[0].address.province',
              val,
            ),
            placeholder: t('common.province'),
            value:
              values.preparationServiceDto?.sanitaryPractices?.[0]?.address
                ?.province || '',
          }}
        />
        <Input
          checked={
            !!values.preparationServiceDto?.sanitaryPractices?.[0]?.atHome
          }
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].atHome',
          )}
          name="preparationServiceDto.sanitaryPractices.atHome"
          placeholder={t('service.preparation.atHome')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={sanitaryAtHomeOnChange}
        />
        <Select<IMasterDataDto>
          key="location"
          disabled={
            isSubmitting
            || disabled
            || values.preparationServiceDto?.sanitaryPractices?.[0]?.atHome
            || !values.preparationServiceDto?.sanitaryPractices?.[0]?.address?.city
          }
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].location',
          )}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="location"
          options={sanitaryPracticeLocations}
          placeholder={t('common.location')}
          value={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.location
              ? normalizedSanitaryPracticeLocations[
                values.preparationServiceDto?.sanitaryPractices?.[0]?.location
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].location',
            val?.code,
          )}
        />
        <Input
          disabled={
            disabled
            || isSubmitting
            || !values.preparationServiceDto?.sanitaryPractices?.[0]?.atHome
          }
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].address.streetName',
          )}
          name="preparationServiceDto.sanitaryPractices.address.streetName"
          placeholder={t('common.domicile')}
          type="text"
          value={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.address
              ?.streetName || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].address.streetName',
            val,
          )}
        />
        <Select<IMasterDataDto>
          key="sanitaryPracticeType"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].type',
          )}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="sanitaryPracticeType"
          options={sanitaryPractices}
          placeholder={t('common.type')}
          value={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.type
              ? normalized.sanitaryPractices[
                values.preparationServiceDto?.sanitaryPractices?.[0]?.type
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].type',
            val?.code,
          )}
        />
        <Select<IMasterDataDto>
          key="thanatologist"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].thanatologist',
          )}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="thanatologist"
          options={thanatologists}
          placeholder={t('service.preparation.thanatologist')}
          value={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.thanatologist
              ? normalized.thanatologists[
                values.preparationServiceDto?.sanitaryPractices?.[0]
                  ?.thanatologist
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].thanatologist',
            val?.code,
          )}
        />
        <Select<IMasterDataDto>
          key="thanatopractor"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].thanatopractor',
          )}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="thanatopractor"
          options={thanatopractors}
          placeholder={t('service.preparation.thanatopractor')}
          value={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.thanatopractor
              ? normalized.thanatopractors[
                values.preparationServiceDto?.sanitaryPractices?.[0]
                  ?.thanatopractor
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].thanatopractor',
            val?.code,
          )}
        />
        <Datepicker
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].startDate',
          )}
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="preparationServiceDto.sanitaryPractices.startDate"
          placeholder={t('common.startDate')}
          selected={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.startDate
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].startDate',
            val,
          )}
        />
        <Datepicker
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].startTime',
          )}
          name="preparationServiceDto.sanitaryPractices.startTime"
          placeholder={t('common.startHour')}
          selected={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.startTime
          }
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].startTime',
            val,
          )}
        />
        <Datepicker
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].endDate',
          )}
          name="preparationServiceDto.sanitaryPractice.endDate"
          placeholder={t('common.endDate')}
          selected={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.endDate
          }
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].endDate',
            val,
          )}
        />
        <Datepicker
          error={getIn(
            errors,
            'preparationServiceDto.sanitaryPractices[0].endTime',
          )}
          name="preparationServiceDto.sanitaryPractices.endTime"
          placeholder={t('common.endHour')}
          selected={
            values.preparationServiceDto?.sanitaryPractices?.[0]?.endTime
          }
          disabled
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.sanitaryPractices[0].endTime',
            val,
          )}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.preparation.applicant')}</legend>
        <Input
          checked={isChecked}
          data-testid="checkboxId"
          disabled={isSubmitting || disabled || !hasPractice}
          error={getIn(errors, 'preparationServiceDto.applicant.declarant')}
          name="preparationServiceDto.applicant"
          placeholder={t('record.declarantData')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={setDeclarantData}
        />
        <Input
          data-testid="inputApplicantName"
          disabled={isChecked || isSubmitting || disabled || !hasPractice}
          error={getIn(errors, 'preparationServiceDto.applicant.name')}
          name="preparationServiceDto.applicant.name"
          placeholder={t('service.preparation.applicant')}
          type="text"
          value={values.preparationServiceDto?.applicant?.name || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.applicant.name', val)}
        />
        <IdentifierSelector
          defaultValue={selector}
          disabled={isChecked || disabled || isSubmitting || !hasPractice}
          errors={{
            cif: getIn(errors, 'preparationServiceDto.applicant.cif'),
            identifier: getIn(
              errors,
              'preparationServiceDto.applicant.identifier',
            ),
            nie: getIn(errors, 'preparationServiceDto.applicant.nie'),
            passport: getIn(errors, 'preparationServiceDto.applicant.passport'),
          }}
          name="preparationServiceDto.applicant.identifier"
          placeholder={t('common.identifierDocument')}
          type="text"
          values={{
            cif: values.preparationServiceDto?.applicant?.cif,
            identifier: values.preparationServiceDto?.applicant?.identifier,
            nie: values.preparationServiceDto?.applicant?.nie,
            passport: values.preparationServiceDto?.applicant?.passport,
          }}
          onBlur={handleBlur}
          onChange={(key, val) => setFieldValue(`preparationServiceDto.applicant.${key}`, val)}
        />
        <Textarea
          disabled={isChecked || isSubmitting || disabled || !hasPractice}
          error={getIn(
            errors,
            'preparationServiceDto.applicant.address.streetName',
          )}
          name="applicant.address.streetName"
          placeholder={t('common.domicile')}
          value={
            values.preparationServiceDto?.applicant?.address?.streetName || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.applicant.address.streetName',
            val,
          )}
        />
        <Input
          disabled={isChecked || isSubmitting || disabled || !hasPractice}
          error={getIn(
            errors,
            'preparationServiceDto.applicant.address.postalCode',
          )}
          name="applicant.address.postalCode"
          placeholder={t('common.postalCode')}
          value={
            values.preparationServiceDto?.applicant?.address?.postalCode || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'preparationServiceDto.applicant.address.postalCode',
            val,
          )}
        />
        <Input
          disabled={isChecked || isSubmitting || disabled || !hasPractice}
          error={getIn(errors, 'preparationServiceDto.applicant.phone')}
          name="applicant.phone"
          placeholder={t('common.phone')}
          type="text"
          value={values.preparationServiceDto?.applicant?.phone || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.applicant.phone', val)}
        />
        <SelectCountryProvinceCity
          cityProps={{
            error: getIn(
              errors,
              'preparationServiceDto.applicant.address.city',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.applicant.address.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue(
                'preparationServiceDto.applicant.address.city',
                val,
              );
            },
            onChangeFull: (val?: CityType) => setFieldValue(
              'preparationServiceDto.applicant.address.postalCode',
              val?.postalCode,
            ),
            placeholder: t('common.city'),
            value: values.preparationServiceDto?.applicant?.address?.city || '',
          }}
          countryProps={{
            error: getIn(
              errors,
              'preparationServiceDto.applicant.address.country',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.applicant.address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'preparationServiceDto.applicant.address.country',
              val,
            ),
            placeholder: t('common.country'),
            value:
              values.preparationServiceDto?.applicant?.address?.country || '',
          }}
          disabled={isChecked || disabled || isSubmitting || !hasPractice}
          initialize={false}
          provinceProps={{
            error: getIn(
              errors,
              'preparationServiceDto.applicant.address.province',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'preparationServiceDto.applicant.address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'preparationServiceDto.applicant.address.province',
              val,
            ),
            placeholder: t('common.province'),
            value:
              values.preparationServiceDto?.applicant?.address?.province || '',
          }}
        />
        {!(isChecked || disabled || !hasPractice || isSubmitting) && (
          <Sign
            className="applicant-signature"
            disabled={isChecked || disabled || isSubmitting || !hasPractice}
            height={150}
            value={values.preparationServiceDto?.applicant?.signature}
            width="100%"
            showClear
            onSign={(val: string) => setFieldValue('preparationServiceDto.applicant.signature', val)}
          />
        )}
      </fieldset>
      <fieldset>
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'preparationServiceDto.comment')}
          name="preparationServiceDto.comment"
          placeholder={t('common.observations')}
          value={values.preparationServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('preparationServiceDto.comment', val)}
        />
      </fieldset>
    </div>
  );
};

export default PreparationForm;
