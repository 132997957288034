import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import FormPage from 'components/base/FormPage';
import Message from 'components/base/Message';

import NotFoundPage from 'components/pages/NotFound';
import UserFields from 'components/user/Form/index';

import { ConflictError } from 'config/apiErrors';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { UserFormType } from 'models/User';
import './index.scss';

const userFormInitialValues = {
  active: false,
  email: '',
  firstSurname: '',
  idrole: undefined,
  name: '',
  secondSurname: '',
  username: '',
  workplace: '',
};

type PageParams = {
  id: string;
  mode: string;
};

const UserForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const { id, mode } = useParams<PageParams>();
  const { roleInfo } = useProvidedAuth();
  const { t } = useTranslation();
  const { location } = useHistory();

  useEffect(() => {
    if (!!id && !!mode && mode === 'edit') {
      setEditing(true);
    }
  }, [id, mode]);

  const handleError = (error: AxiosError) => {
    showErrorToast(
      error instanceof ConflictError ? t('user.duplicated') : t('user.saveKo'),
    );
  };

  if (!roleInfo.isAdmin && id === 'add') {
    return <NotFoundPage />;
  }
  if (
    !roleInfo.isAdmin
    || (location.pathname.indexOf(config.url.newUser) > -1 && !!id)
  ) {
    return (
      <div className="error-page" id="user-form-page">
        <Message type="error">{t('user.newUserError')}</Message>
      </div>
    );
  }

  return (
    <div id="user-form-page">
      <FormPage<UserFormType>
        backUrl={config.url.landing}
        editing={editing}
        fields={(props, disabled) => (
          <UserFields disabled={disabled} formikProps={props} />
        )}
        getFunction={config.apiFunus.users.getUser}
        id={id}
        initialValues={userFormInitialValues}
        saveFunction={config.apiFunus.users.saveUser}
        title={{
          add: t('user.new'),
          edit: t('user.edit'),
          see: t('user.detail'),
        }}
        updateFunction={config.apiFunus.users.editUser}
        validateForm={(values: UserFormType) => config.validator(values, 'user')}
        validateOnBlur
        onError={handleError}
        onSave={() => showSuccessToast(t('user.saveOk'))}
      />
    </div>
  );
};

export default UserForm;
