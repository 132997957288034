import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { capitalizeString } from 'utils/helpers';

import Datepicker from 'components/base/Datepicker';
import IdentifierSelector from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import useCemetery from 'hooks/useCemetery';
import useCemeterySection from 'hooks/useCemeterySection';
import { CemeteryType, CityType, IMasterDataDto } from 'models/MasterData';
import { SaveService } from 'models/MasterService';
import { DeathType, SaveRecord } from 'models/Record';
import { RootState } from 'store';

type IntermentProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const IntermentForm: FC<IntermentProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const { t } = useTranslation();
  const { concessionTypes, nicheTypes } = useSelector(
    (state: RootState) => state.masterData,
  );
  const { normalized } = useSelector((state: RootState) => state);
  const { cemeteries, normalizedCemeteries, getCemetery } = useCemetery(
    values.intermentServiceDto?.intermentAddress?.city,
  );
  const { cemeterySections, normalizedCemeterySections } = useCemeterySection(
    values.intermentServiceDto?.intermentAddress?.city,
    values.intermentServiceDto?.cemetery,
  );

  return (
    <div className={classNames('interment-form', className)}>
      <fieldset>
        <legend>{t('service.INHUMACION')}</legend>
        <SelectCountryProvinceCity
          key="intermentAddress"
          cityProps={{
            error: getIn(errors, 'intermentServiceDto.intermentAddress.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'intermentAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('intermentServiceDto.intermentAddress.city', val);
              setFieldValue('intermentServiceDto.cemetery', undefined);
              setFieldValue('intermentServiceDto.section', undefined);
            },
            placeholder: t('common.city'),
            value: values.intermentServiceDto?.intermentAddress?.city || '',
          }}
          countryProps={{
            error: getIn(
              errors,
              'intermentServiceDto.intermentAddress.country',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'intermentAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.intermentAddress.country',
              val,
            ),
            placeholder: t('common.country'),
            value: values.intermentServiceDto?.intermentAddress?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(
              errors,
              'intermentServiceDto.intermentAddress.province',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'intermentAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.intermentAddress.province',
              val,
            ),
            placeholder: t('common.province'),
            value: values.intermentServiceDto?.intermentAddress?.province || '',
          }}
        />
        <Select<CemeteryType>
          key="cemetery"
          disabled={
            isSubmitting
            || disabled
            || !values.intermentServiceDto?.intermentAddress?.city
          }
          error={getIn(errors, 'intermentServiceDto.cemetery')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="cemetery"
          options={cemeteries}
          placeholder={t('service.interment.cemetery')}
          testid="interment-cemetery"
          value={
            values.intermentServiceDto?.cemetery
              ? normalizedCemeteries[values.intermentServiceDto?.cemetery]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => {
            setFieldValue('intermentServiceDto.cemetery', val?.code);
            const selectedCemetery = getCemetery(val?.code);
            if (selectedCemetery) {
              setFieldValue('intermentServiceDto.cemeteryCoffin', selectedCemetery?.coffinInterment);
              setFieldValue('intermentServiceDto.cemeteryAshes', selectedCemetery?.ashesInterment);
            }
          }}
        />
        <Select<IMasterDataDto>
          key="nicheSection"
          disabled={
            isSubmitting || disabled || !values.intermentServiceDto?.cemetery
          }
          error={getIn(errors, 'intermentServiceDto.nicheSection')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="nicheSection"
          options={cemeterySections}
          placeholder={t('service.interment.section')}
          value={
            values.intermentServiceDto?.nicheSection
              ? normalizedCemeterySections[
                values.intermentServiceDto?.nicheSection
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.nicheSection', val?.code)}
        />
        <Select<IMasterDataDto>
          key="niche"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.niche')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="niche"
          options={nicheTypes}
          placeholder={t('service.interment.sepulture')}
          value={
            values.intermentServiceDto?.niche
              ? normalized.nicheTypes[values.intermentServiceDto?.niche]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.niche', val?.code)}
        />
        <Input
          key="nicheNumber"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.nicheNumber')}
          id="nicheNumber"
          name="nicheNumber"
          placeholder={t('service.interment.number')}
          value={values.intermentServiceDto?.nicheNumber || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.nicheNumber', val)}
        />
        <Input
          key="nicheTier"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.nicheTier')}
          min={1}
          name="nicheTier"
          placeholder={t('service.interment.floor')}
          type="number"
          value={values.intermentServiceDto?.nicheTier || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.nicheTier', val)}
        />
        <Datepicker
          key="intermentDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'intermentServiceDto.intermentDate')}
          minDate={
            values.intermentServiceDto?.preparationDate
              ? new Date(values.intermentServiceDto?.preparationDate)
              : record?.deathData?.deathDate
                && new Date(record.deathData.deathDate)
          }
          name="intermentDate"
          placeholder={t('service.interment.intermentDate')}
          selected={values.intermentServiceDto?.intermentDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.intermentDate', val)}
        />
        <Datepicker
          key="intermentTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'intermentServiceDto.intermentTime')}
          name="intermentTime"
          placeholder={t('service.interment.intermentTime')}
          selected={values.intermentServiceDto?.intermentTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.intermentTime', val)}
        />
        <Datepicker
          key="preparationDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'intermentServiceDto.preparationDate')}
          maxDate={
            values.intermentServiceDto?.intermentDate
            && new Date(values.intermentServiceDto.intermentDate)
          }
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="preparationDate"
          placeholder={t('service.interment.nichePreparationDate')}
          selected={values.intermentServiceDto?.preparationDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.preparationDate', val)}
        />
        <Datepicker
          key="preparationTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'intermentServiceDto.preparationTime')}
          name="preparationTime"
          placeholder={t('service.interment.nichePreparationTime')}
          selected={values.intermentServiceDto?.preparationTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.preparationTime', val)}
        />
        <Input
          key="familiarPresentPreparation"
          checked={!!values.intermentServiceDto?.familiarPresentPreparation}
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'intermentServiceDto.familiarPresentPreparation',
          )}
          name="familiarPresentPreparation"
          placeholder={t('service.interment.familyPresence')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.familiarPresentPreparation', val)}
        />
        <Input
          key="takeMeasurementsPhoto"
          checked={!!values.intermentServiceDto?.takeMeasurementsPhoto}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.takeMeasurementsPhoto')}
          name="takeMeasurementsPhoto"
          placeholder={t('service.interment.measurementsAndPhotos')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.takeMeasurementsPhoto', val)}
        />
        <Input
          key="judicial"
          checked={record?.deathData?.deathType === DeathType.judicial}
          name="judicial"
          placeholder={t('service.interment.judicial')}
          type="checkbox"
          disabled
          onBlur={handleBlur}
        />
        <Input
          key="zincBox"
          checked={!!values.intermentServiceDto?.zincBox}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.zincBox')}
          id="zincBox"
          name="zincBox"
          placeholder={t('service.interment.zincBox')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.zincBox', val)}
        />
        <Textarea
          key="intermentComment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.intermentComment')}
          name="intermentComment"
          placeholder={t('common.observations')}
          value={values.intermentServiceDto?.intermentComment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.intermentComment', val)}
        />
        <Input
          key="ashes"
          checked={!!values.intermentServiceDto?.ashes}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.ashes')}
          id="ashes"
          name="ashes"
          placeholder={t('service.interment.ashes')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.ashes', val)}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.interment.concession.title')}</legend>
        <Select<IMasterDataDto>
          key="concessionType"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.concession.type')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="concessionType"
          options={concessionTypes}
          placeholder={t('service.interment.concession.type')}
          value={
            values.intermentServiceDto?.concession?.type
              ? normalized.concessionTypes[
                values.intermentServiceDto?.concession?.type
              ]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.concession.type', val?.code)}
        />
        <Input
          key="concessionTemporality"
          checked={!!values.intermentServiceDto?.concession?.temporality}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.concession.temporality')}
          name="concessionTemporality"
          placeholder={t('service.interment.concession.temporality')}
          type="text"
          value={values.intermentServiceDto?.concession?.temporality || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.concession.temporality', val)}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.interment.concession.holder')}</legend>
        <Input
          key="concessionHolder"
          checked={!!values.intermentServiceDto?.concession?.holder}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.concession.holder')}
          name="concessionHolder"
          placeholder={t('common.name')}
          type="text"
          value={values.intermentServiceDto?.concession?.holder || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.concession.holder', val)}
        />
        <IdentifierSelector
          disabled={disabled || isSubmitting}
          errors={{
            cif: getIn(errors, 'intermentServiceDto.concession.holderCif'),
            identifier: getIn(
              errors,
              'intermentServiceDto.concession.holderIdentifier',
            ),
            nie: getIn(errors, 'intermentServiceDto.concession.holderNie'),
            passport: getIn(
              errors,
              'intermentServiceDto.concession.holderPassport',
            ),
          }}
          name="intermentServiceDto.concession.identifier"
          placeholder={t('common.identifierDocument')}
          type="text"
          values={{
            cif: values.intermentServiceDto?.concession?.holderCif,
            identifier:
              values.intermentServiceDto?.concession?.holderIdentifier,
            nie: values.intermentServiceDto?.concession?.holderNie,
            passport: values.intermentServiceDto?.concession?.holderPassport,
          }}
          onBlur={handleBlur}
          onChange={(key, val) => setFieldValue(
            `intermentServiceDto.concession.holder${capitalizeString(key)}`,
            val,
          )}
        />
        <SelectCountryProvinceCity
          key="concessionHolderAddress"
          cityProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.holderAddress.city',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionHolderAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.holderAddress.city',
              val,
            ),
            onChangeFull: (val?: CityType) => setFieldValue(
              'intermentServiceDto.concession.holderAddress.postalCode',
              val?.postalCode,
            ),
            placeholder: t('common.city'),
            value:
              values.intermentServiceDto?.concession?.holderAddress?.city || '',
          }}
          countryProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.holderAddress.country',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionHolderAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.holderAddress.country',
              val,
            ),
            placeholder: t('common.country'),
            value:
              values.intermentServiceDto?.concession?.holderAddress?.country
              || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.holderAddress.province',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionHolderAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.holderAddress.province',
              val,
            ),
            placeholder: t('common.province'),
            value:
              values.intermentServiceDto?.concession?.holderAddress?.province
              || '',
          }}
        />
        <Textarea
          key="concessionHolderAddress.streetName"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'intermentServiceDto.concession.holderAddress.streetName',
          )}
          name="concessionHolderAddress.streetName"
          placeholder={t('common.address')}
          value={
            values.intermentServiceDto?.concession?.holderAddress?.streetName
            || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'intermentServiceDto.concession.holderAddress.streetName',
            val,
          )}
        />
        <Input
          key="concessionHolderAddress.postalCode"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'intermentServiceDto.concession.holderAddress.postalCode',
          )}
          name="concessionHolderAddress.postalCode"
          placeholder={t('common.postalCode')}
          value={
            values.intermentServiceDto?.concession?.holderAddress?.postalCode
            || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'intermentServiceDto.concession.holderAddress.postalCode',
            val,
          )}
        />
      </fieldset>
      <fieldset>
        <legend>{t('service.interment.concession.beneficiary')}</legend>
        <Input
          key="concessionBeneficiary"
          checked={!!values.intermentServiceDto?.concession?.beneficiary}
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.concession.beneficiary')}
          name="concessionBeneficiary"
          placeholder={t('common.name')}
          type="text"
          value={values.intermentServiceDto?.concession?.beneficiary || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.concession.beneficiary', val)}
        />
        <IdentifierSelector
          disabled={disabled || isSubmitting}
          errors={{
            cif: getIn(errors, 'intermentServiceDto.concession.beneficiaryCif'),
            identifier: getIn(
              errors,
              'intermentServiceDto.concession.beneficiaryIdentifier',
            ),
            nie: getIn(errors, 'intermentServiceDto.concession.beneficiaryNie'),
            passport: getIn(
              errors,
              'intermentServiceDto.concession.beneficiaryPassport',
            ),
          }}
          name="intermentServiceDto.concession.identifier"
          placeholder={t('common.identifierDocument')}
          type="text"
          values={{
            cif: values.intermentServiceDto?.concession?.beneficiaryCif,
            identifier:
              values.intermentServiceDto?.concession?.beneficiaryIdentifier,
            nie: values.intermentServiceDto?.concession?.beneficiaryNie,
            passport:
              values.intermentServiceDto?.concession?.beneficiaryPassport,
          }}
          onBlur={handleBlur}
          onChange={(key, val) => setFieldValue(
            `intermentServiceDto.concession.beneficiary${capitalizeString(
              key,
            )}`,
            val,
          )}
        />
        <SelectCountryProvinceCity
          key="concessionBeneficiaryAddress"
          cityProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.beneficiaryAddress.city',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionBeneficiaryAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.beneficiaryAddress.city',
              val,
            ),
            placeholder: t('common.city'),
            value:
              values.intermentServiceDto?.concession?.beneficiaryAddress
                ?.city || '',
          }}
          countryProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.beneficiaryAddress.country',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionBeneficiaryAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.beneficiaryAddress.country',
              val,
            ),
            placeholder: t('common.country'),
            value:
              values.intermentServiceDto?.concession?.beneficiaryAddress
                ?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(
              errors,
              'intermentServiceDto.concession.beneficiaryAddress.province',
            ),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'concessionBeneficiaryAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue(
              'intermentServiceDto.concession.beneficiaryAddress.province',
              val,
            ),
            placeholder: t('common.province'),
            value:
              values.intermentServiceDto?.concession?.beneficiaryAddress
                ?.province || '',
          }}
        />
        <Textarea
          key="concessionBeneficiaryAddress.streetName"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'intermentServiceDto.concession.beneficiaryAddress.streetName',
          )}
          name="concessionBeneficiaryAddress.streetName"
          placeholder={t('common.address')}
          value={
            values.intermentServiceDto?.concession?.beneficiaryAddress
              ?.streetName || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'intermentServiceDto.concession.beneficiaryAddress.streetName',
            val,
          )}
        />
        <Input
          key="concessionBeneficiaryAddress.postalCode"
          disabled={isSubmitting || disabled}
          error={getIn(
            errors,
            'intermentServiceDto.concession.beneficiaryAddress.postalCode',
          )}
          name="concessionBeneficiaryAddress.postalCode"
          placeholder={t('common.postalCode')}
          value={
            values.intermentServiceDto?.concession?.beneficiaryAddress
              ?.postalCode || ''
          }
          onBlur={handleBlur}
          onChange={(val) => setFieldValue(
            'intermentServiceDto.concession.beneficiaryAddress.postalCode',
            val,
          )}
        />
      </fieldset>
      <fieldset>
        <Textarea
          key="comment"
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'intermentServiceDto.concession.comment')}
          name="comment"
          placeholder={t('common.observations')}
          value={values.intermentServiceDto?.concession?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('intermentServiceDto.concession.comment', val)}
        />
      </fieldset>
    </div>
  );
};

export default IntermentForm;
