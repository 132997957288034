import { useState } from 'react';

import { orderLineController } from 'config/apiFunus';

import { CommercialOverviewUsingGetHook } from './types';

export const useCommercialOverviewUsingGet = (
  errorMessage: (message: string) => void,
): CommercialOverviewUsingGetHook => {
  const base64Data = 'data:application/pdf;base64,';

  const [comercialBase64, setcomercialBase64] = useState<string>();

  const getComercialPdf = async (erpId: number) => orderLineController
    .commercialOverviewUsingGet(erpId)
    .then((response) => {
      const unit8 = new Uint8Array(response);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const binary = String.fromCharCode.apply(null, unit8 as any);
      const base64 = btoa(binary);
      setcomercialBase64(`${base64Data}${base64}`);

      return `${base64Data}${base64}`;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    comercialBase64,
    getComercialPdf,
  };
};
