import { FormikProps, getIn } from 'formik';
import React, {
  FC, Fragment, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateForSearch, getAgeLiteral } from 'utils/dateManager';

import Datepicker from 'components/base/Datepicker';
import Input from 'components/base/Input';
import RadioButton from 'components/base/RadioButton';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { Centers, useCenters } from 'hooks/useCenters';
import { useDeathLocation } from 'hooks/useDeathLocation';
import { useDeathTypes } from 'hooks/useDeathTypes';
import useDoctor from 'hooks/useDoctor';
import { useGroupCauses } from 'hooks/useGroupCauses';
import useTribunal from 'hooks/useTribunal';
import { i18n } from 'i18n';
import {
  CityType,
  IMasterDataDto,
  LocationExtendedType,
} from 'models/MasterData';
import { DefaultSelectType } from 'models/Others';
import { DeathType, SaveRecord } from 'models/Record';
import { IAudit } from 'modules/budget/models/Audit/types';

const today = new Date();

const atHomeOptions = [
  { label: i18n.t('record.deathData.atHome'), value: 'atHome' },
  { label: i18n.t('record.steps.other'), value: 'other' },
];

type DeathFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const DeathFields: FC<DeathFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
}) => {
  const { t } = useTranslation();
  const { deathTypes, getDeathType } = useDeathTypes();
  const { doctors, getDoctor } = useDoctor(values.deathData?.deathType);
  const { deathLocations, getDeathLocationByCode } = useDeathLocation(
    values.deathData?.address?.city,
  );
  const { tribunals, getTribunalByCode } = useTribunal(
    values.deathData?.address?.city,
  );
  const { groupCauses, getGroupCause } = useGroupCauses();
  const { centers, getCenter } = useCenters();

  const [toJuditial, settoJuditial] = useState(false);

  useEffect(() => {
    if (toJuditial && doctors.length !== 0) {
      setFieldValue('deathData.doctor', doctors.find(() => true)?.code);
      settoJuditial(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctors]);

  const radioButtonOnChange = (val: string) => {
    setFieldValue('deathData.atHome', val === 'atHome');

    if (val === 'other') {
      const pickupAddressAudit = values?.deathData?.pickupAddress
        ? {
          createdBy: (values?.deathData?.pickupAddress as IAudit).createdBy,
          createdDate: (values?.deathData?.pickupAddress as IAudit).createdDate,
          id: (values?.deathData?.pickupAddress as IAudit).id,
          idVersion: (values?.deathData?.pickupAddress as IAudit).idVersion,
        }
        : {};

      setFieldValue(
        'deathData.pickupAddress',
        values?.deathData?.address ? {
          ...pickupAddressAudit,
          city: values.deathData.address.city,
          country: values.deathData.address.country,
          fullAddress: values.deathData.address.fullAddress,
          postalCode: values.deathData.address.postalCode,
          province: values.deathData.address.province,
          streetName: values.deathData.address.streetName,
          streetNumber: values.deathData.address.streetNumber,
        } : {},
      );
    }

    if (val === 'atHome') {
      setFieldValue('deathData.location', undefined);
      setFieldValue(
        'deathData.pickupAddress.country',
        values?.deceasedData?.deceasedAddress?.country,
      );
      setFieldValue(
        'deathData.pickupAddress.province',
        values?.deceasedData?.deceasedAddress?.province,
      );
      setFieldValue(
        'deathData.pickupAddress.city',
        values?.deceasedData?.deceasedAddress?.city,
      );
      setFieldValue(
        'deathData.pickupAddress.streetName',
        values?.deceasedData?.deceasedAddress?.streetName,
      );
      setFieldValue(
        'deathData.pickupAddress.postalCode',
        values?.deceasedData?.deceasedAddress?.postalCode,
      );
      setFieldValue('deathData.location', undefined);
    }
  };

  const changeLocation = (val: LocationExtendedType) => {
    setFieldValue('deathData.location', val?.code);
    setFieldValue('deathData.pickupAddress', {
      city: values?.deathData?.address?.city,
      country: values?.deathData?.address?.country,
      postalCode: val?.postalCode,
      province: values?.deathData?.address?.province,
      streetName: val?.address,
    });
  };

  const changeDeathDate = (val: string) => {
    const birthDate = formatDateForSearch(values.deceasedData?.birthday);
    const deathDate = formatDateForSearch(values.deathData?.deathDate);
    setFieldValue('deathData.deathDate', val);
    setFieldValue(
      'deceasedData.age',
      birthDate && deathDate
        ? getAgeLiteral(birthDate, deathDate)
        : '',
    );
  };

  const excludeDirectionCenter = (
    center: IMasterDataDto,
  ): boolean => center.code !== Centers.DIRECTION;

  return (
    <Fragment>
      <fieldset>
        <legend>{t('record.deathData.deathType')}</legend>
        <RadioButton<IMasterDataDto>
          checked={
            getDeathType(values.deathData?.deathType)?.code
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'deathData.deathType')}
          getLabel={({ description }) => t(`record.deathData.type.${description.toLowerCase()}`)}
          getValue={(el) => el.code}
          name="deathData.deathType"
          options={deathTypes}
          placeholder={t('record.deathData.deathType')}
          onChange={(val) => {
            settoJuditial(val === DeathType.judicial);
            setFieldValue('deathData.deathType', val);
            setFieldValue('deathData.approximatedDate', undefined);
            setFieldValue('deathData.trialCourt', undefined);
            setFieldValue('deathData.errands', undefined);
          }}
        />
        {!createMode && (
          <Select<IMasterDataDto>
            disabled={
              disabled
              || isSubmitting
              || values.deathData?.deathType !== DeathType.judicial
              || !values.deathData?.address?.city
            }
            error={getIn(errors, 'deathData.trialCourt')}
            getLabel={({ description }) => description}
            getValue={({ code }) => code}
            name="deathData.trialCourt"
            options={tribunals}
            placeholder={t('record.deathData.trialCourt')}
            value={getTribunalByCode(values.deathData?.trialCourt)}
            searchable
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deathData.trialCourt', val?.code)}
          />
        )}
        {!createMode && (
          <Input
            disabled={
              disabled
              || isSubmitting
              || values.deathData?.deathType !== DeathType.judicial
            }
            error={getIn(errors, 'deathData.errands')}
            name="deathData.errands"
            placeholder={t('record.deathData.errands')}
            type="text"
            value={values.deathData?.errands || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deathData.errands', val)}
          />
        )}
        {!createMode && (
          <Input
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deathData.deathCause')}
            maxLength={100}
            name="deathData.deathCause"
            placeholder={t('record.deathData.deathCause')}
            type="text"
            value={values.deathData?.deathCause || ''}
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deathData.deathCause', val)}
          />
        )}
        {!createMode && (
          <Select<IMasterDataDto>
            disabled={disabled || isSubmitting || !values.deathData?.deathType}
            error={getIn(errors, 'deathData.doctor')}
            getLabel={({ description }) => description}
            getValue={({ code }) => code}
            limit={100}
            name="deathData.doctor"
            options={doctors}
            placeholder={t('record.deathData.doctor')}
            value={getDoctor(values.deathData?.doctor)}
            searchable
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deathData.doctor', val?.code)}
          />
        )}
        {!createMode && (
          <Select<IMasterDataDto>
            disabled={disabled || isSubmitting}
            error={getIn(errors, 'deathData.diseases')}
            getLabel={({ description }) => description}
            getValue={({ code }) => code}
            name="deathData.diseases"
            options={groupCauses}
            placeholder={t('record.deathData.diseases')}
            value={getGroupCause(values.deathData?.diseases)}
            searchable
            onBlur={handleBlur}
            onChange={(val) => setFieldValue('deathData.diseases', val?.code)}
          />
        )}
      </fieldset>
      <fieldset>
        <legend>{t('record.steps.death')}</legend>
        <Input
          checked={!!values.deathData?.medicalCertificate}
          data-testid="deathData.medicalCertificate"
          disabled={disabled || isSubmitting || values.hasPickUp}
          error={getIn(errors, 'deathData.medicalCertificate')}
          name="deathData.medicalCertificate"
          placeholder={t('record.deathData.medicalCertificate')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.medicalCertificate', val)}
        />
        <Datepicker
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deathData.deathDate')}
          maxDate={today}
          name="deathData.deathDate"
          placeholder={t('common.date')}
          selected={values.deathData?.deathDate}
          onBlur={handleBlur}
          onChange={changeDeathDate}
        />
        <Input
          disabled={
            disabled
            || isSubmitting
            || values.deathData?.deathType !== DeathType.judicial
          }
          error={getIn(errors, 'deathData.approximatedDate')}
          id="deathData.approximatedDate"
          maxLength={50}
          name="deathData.approximatedDate"
          placeholder={t('record.deathData.approximatedDate')}
          type="text"
          value={values.deathData?.approximatedDate || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.approximatedDate', val)}
        />
        <Datepicker
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deathData.deathTime')}
          name="deathData.deathTime"
          placeholder={t('common.hour')}
          selected={values.deathData?.deathTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.deathTime', val)}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'deathData.comment')}
          name="deathData.comment"
          placeholder={t('common.observations')}
          value={values.deathData?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.comment', val)}
        />
        <SelectCountryProvinceCity
          cityProps={{
            error: getIn(errors, 'deathData.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.address.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('deathData.address.city', val);
              setFieldValue('deathData.trialCourt', undefined);
              setFieldValue('deathData.location', undefined);
            },
            placeholder: t('common.city'),
            value: values.deathData?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'deathData.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('deathData.address.country', val),
            placeholder: t('common.country'),
            value: values.deathData?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'deathData.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('deathData.address.province', val),
            placeholder: t('common.province'),
            value: values.deathData?.address?.province || '',
          }}
        />
        <Select<IMasterDataDto>
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deathData.destinationCenter')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="deathData.destinationCenter"
          options={centers.filter(excludeDirectionCenter)}
          placeholder={t('record.deathData.destinationCenter')}
          value={getCenter(values.deathData?.destinationCenter)}
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.destinationCenter', val?.code)}
        />
      </fieldset>
      <fieldset>
        <legend>{t('record.deathData.pickupAddress')}</legend>
        <RadioButton<DefaultSelectType>
          checked={
            values.deathData?.atHome === undefined
              ? ''
              : `${
                (values.deathData.atHome
                  ? atHomeOptions[0]
                  : atHomeOptions[1]
                ).value
              }`
          }
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'deathData.atHome')}
          getLabel={(el) => el.label}
          getValue={(el) => el.value}
          name="deathData.atHome"
          options={atHomeOptions}
          placeholder={t('record.deathData.pickupType')}
          onChange={radioButtonOnChange}
        />
        <Select<LocationExtendedType>
          disabled={
            disabled
            || isSubmitting
            || values.deathData?.atHome === true
            || values.deathData?.atHome === undefined
            || !values.deathData?.address?.city
          }
          error={getIn(errors, 'deathData.location')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="deathData.location"
          options={deathLocations}
          placeholder={t('record.deathData.pickupAddress')}
          value={getDeathLocationByCode(values.deathData?.location)}
          searchable
          onBlur={handleBlur}
          onChange={changeLocation}
        />
        <SelectCountryProvinceCity
          cityProps={{
            disabled: disabled || isSubmitting,
            error: getIn(errors, 'deathData.pickupAddress.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.pickupAddress.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('deathData.pickupAddress.city', val),
            onChangeFull: (val?: CityType) => setFieldValue(
              'deathData.pickupAddress.postalCode',
              val?.postalCode,
            ),
            placeholder: `${t('common.city')} ${t('record.deathData.pickup')}`,
            value: values.deathData?.pickupAddress?.city || '',
          }}
          countryProps={{
            disabled: disabled || isSubmitting,
            error: getIn(errors, 'deathData.pickupAddress.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.pickupAddress.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('deathData.pickupAddress.country', val),
            placeholder: `${t('common.country')} ${t(
              'record.deathData.pickup',
            )}`,
            value: values.deathData?.pickupAddress?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            disabled: disabled || isSubmitting,
            error: getIn(errors, 'deathData.pickupAddress.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'deathData.pickupAddress.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('deathData.pickupAddress.province', val),
            placeholder: `${t('common.province')} ${t(
              'record.deathData.pickup',
            )}`,
            value: values.deathData?.pickupAddress?.province || '',
          }}
        />
        <Textarea
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deathData.pickupAddress.streetName')}
          name="deathData.pickupAddress.streetName"
          placeholder={`${t('common.street')} ${t('record.deathData.pickup')}`}
          value={values.deathData?.pickupAddress?.streetName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.pickupAddress.streetName', val)}
        />
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'deathData.pickupAddress.postalCode')}
          name="deathData.pickupAddress.postalCode"
          placeholder={`${t('common.postalCode')} ${t(
            'record.deathData.pickup',
          )}`}
          type="text"
          value={values.deathData?.pickupAddress?.postalCode || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('deathData.pickupAddress.postalCode', val)}
        />
      </fieldset>
    </Fragment>
  );
};

export default DeathFields;
