export const arrayBufferToBase64 = (
  input: ArrayBuffer,
  base64Data = 'data:application/pdf;base64,',
): string => {
  const unit8 = new Uint8Array(input);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const binary = String.fromCharCode.apply(null, unit8 as any);
  const base64 = btoa(binary);
  return `${base64Data}${base64}`;
};
