import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCities } from 'actions/masterData';

import { CityType } from 'models/MasterData';
import { RootState } from 'store';

export interface CitiesHook {
  cities: CityType[],
  getCityByNormalizedCode: (
    countryCode: string | undefined,
    provinceCode: string | undefined,
    cityCode: string | undefined) => CityType | undefined
}

export const useCities = (): CitiesHook => {
  const dispatch = useDispatch();

  const { items, normalizedCities } = useSelector(
    (state: RootState) => ({
      items: state.masterData.cities,
      normalizedCities: state.normalized.cities,
    }),
  );

  const getCityByNormalizedCode = useCallback(
    (
      countryCode: string | undefined,
      provinceCode: string | undefined,
      cityCode: string | undefined,
    ) => {
      const normalizedCityCode = `${countryCode}${provinceCode}${cityCode}`;
      return normalizedCityCode
      && normalizedCities[normalizedCityCode]
        ? items.find((city: CityType) => city.code === normalizedCities[normalizedCityCode].code)
        : undefined;
    },
    [normalizedCities, items],
  );

  useEffect(() => {
    dispatch(getCities());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cities: items || [],
    getCityByNormalizedCode,
  };
};
