import config from 'config';

import { ISaveConfigurationRequest } from 'modules/budget/api/budgetMasterDataClient/types';

export enum ValidationSchemas {
  ARTICLE = 'article',
  ARTICLE_DISAGREEMENT = 'articleDisagreement',
  BUDGET_RULE = 'budgetRule',
}

export interface ValidatorHook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateArticleDisagreement: (values: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateArticleAddRequest: (values: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateBudgetRule : (values: ISaveConfigurationRequest) => Promise<any>;
}

export const useValidator = (): ValidatorHook => ({
  validateArticleAddRequest: async (values) => config.validator(values, ValidationSchemas.ARTICLE),
  validateArticleDisagreement: async (
    values,
  ) => config.validator(values, ValidationSchemas.ARTICLE_DISAGREEMENT),
  validateBudgetRule: async (
    values,
  ) => config.validator(values, ValidationSchemas.BUDGET_RULE),
});
