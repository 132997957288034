import { FormikProps, FormikValues } from 'formik';
import React, {
  FC, ReactElement, useCallback, useEffect, useState,
} from 'react';
import { Settings } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import config from 'config';

import {
  checkHaveAtLeastOnePermission,
  checkPermission,
} from 'utils/permissions';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Button from 'components/base/Button';
import FormPage from 'components/base/FormPage';
import { StepButtonProps, FormStep } from 'components/base/StepsForm/types';

import {
  receptionSteps,
  recordFormInitialValues,
  steps,
} from 'components/record/Form';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import {
  DeathType,
  RecordStateEnum,
  RecordTypeEnum,
  SaveRecord,
} from 'models/Record';
import { RootState } from 'store';

import './index.scss';

type PageParams = {
  id: string;
};

const RecordForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const [record, setRecord] = useState<SaveRecord>();
  const [redirect, setRedirect] = useState<string | number>();
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  const { normalized } = useSelector((state: RootState) => state);

  useEffect(() => {
    const arr = window.location.pathname.split('/');

    setEditing(
      (!record || record.state !== RecordStateEnum.FINISHED)
        && arr[arr.length - 1] === 'edit',
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, record]);

  const getFields = (
    props: FormikProps<SaveRecord>,
    disabled?: boolean,
  ): ReactElement => {
    if (
      checkPermission(
        SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
        user?.role.permissions,
      )
      && (!id || id === 'add')
      && props
    ) {
      return receptionSteps(props, disabled);
    }
    return <div />;
  };

  const getSteps = (values: SaveRecord): FormStep<SaveRecord>[] => {
    if (
      checkPermission(
        SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
        user?.role.permissions,
      )
      && (!id || id === 'add')
    ) {
      return [];
    }
    return steps(values, normalized.recordTypes);
  };

  useEffect(() => {
    setRedirect(undefined);
  }, [id]);

  const onSave = (resParams: SaveRecord, params: SaveRecord) => {
    showSuccessToast(params?.id ? t('record.editOk') : t('record.saveOk'));
    if (!params?.id) {
      setRedirect(resParams.id);
    }
  };

  const getButtons = useCallback((values: FormikValues): StepButtonProps[] => {
    const buttons: StepButtonProps[] = [];

    const canCreateReception = !!values.receptionData && values.canCreateReception;
    const canCreatePickup = !values.hasReception
      && checkHaveAtLeastOnePermission(
        [
          SimpleUserRequestPermissionNames.RECORD_WRITE,
          SimpleUserRequestPermissionNames.RECORD_ADD,
        ],
        user?.role.permissions,
      )
      && id
      && (values?.deathData?.medicalCertificate
        || values.deathData.deathType === DeathType.judicial)
      && !values.hasPickUp
      && !(
        // values.preneed ||
        (
          !values.deathData
          || (values.deathData.atHome && !values.deathData.pickupAddress?.city)
          || (!values.deathData.atHome && !values.deathData.location)
        )
      );

    if (canCreateReception) {
      buttons.push(
        {
          color: 'accent',
          key: 'createReception',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClickPromise: (vals: any) => config.apiFunus.record.createReception({
            id: vals.id,
            values: vals as SaveRecord,
          }),
          text: t('record.createReception'),
        },
      );
    } else if (canCreatePickup) {
      buttons.push(
        {
          color: 'attention',
          key: 'createPickup',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClickPromise: (vals: any) => config.apiFunus.record.createPickup({
            id: vals.id,
            values: vals as SaveRecord,
          }),
          text: t('record.createPickup'),
        },
      );
    }

    return buttons;
  },
  [id, t, user?.role.permissions]);

  if (redirect) {
    return (
      <Redirect
        from={config.url.newRecord}
        to={`${config.url.records}/${redirect}/edit`}
        exact
      />
    );
  }

  return (
    <div id="record-form-page">
      {id
        && id !== 'add'
        && checkPermission(
          SimpleUserRequestPermissionNames.ADVICE_WRITE,
          user?.role?.permissions,
        ) && (
          <Button
            className="services-button"
            color="transparent"
            rightAddon={<Settings />}
            text={t('menu.detailservices')}
            to={`${config.url.services}/${id}`}
          />
      )}
      <FormPage<SaveRecord>
        buttons={getButtons}
        editing={editing}
        fields={getFields}
        getFunction={config.apiFunus.record.getRecordById}
        id={id}
        initialValues={
          id
            ? recordFormInitialValues
            : {
              ...recordFormInitialValues,
              sendByEmail: true,
              type: RecordTypeEnum.DEATH,
            }
        }
        redirectOnSave={id === 'add'}
        saveFunction={config.apiFunus.record.saveRecord}
        steps={getSteps}
        stepsForm={
          !(
            checkPermission(
              SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
              user?.role.permissions,
            )
            && (!id || id === 'add')
          )
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        subtitle={(val: any) => `${val?.deceasedData?.fullName || ''}`}
        title={{
          add: t('record.new'),
          edit: (val) => `${t('record.edit')} (${val.erpId})`,
          see: (val) => `${t('record.detail')} (${val.erpId})`,
        }}
        updateFunction={config.apiFunus.record.editRecord}
        validateForm={(values: SaveRecord) => config.validator(values, 'record')}
        validateOnBlur
        validateOnChange
        onError={(err, params) => {
          if (err?.response?.data && typeof err.response.data === 'string') {
            showErrorToast(
              err.response.data === 'deceased.exist'
                ? t('error.deceasedExist')
                : err.response.data,
            );
          } else if (err?.message && typeof err.message === 'string') {
            showErrorToast(err.message);
          } else {
            showErrorToast(
              `${params?.id ? t('record.editKo') : t('record.saveKo')}`,
            );
          }
        }}
        onGet={setRecord}
        onSave={onSave}
      />
    </div>
  );
};

export default RecordForm;
