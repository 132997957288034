import { AxiosError } from "axios";
import { formatDateAndHour } from "utils/dateManager";
import errorCat from 'i18n/cat/error';

export class BackendUnmanagedError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = error.message || errorCat[500];
    this.name = "BackendUnmanagedError";
  }
}

export class EkonError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    if (typeof error.response?.data === 'object') {
      try {
        const decoder = new TextDecoder('utf-8');
        const uint8Array = new Uint8Array(error.response?.data);
        const message = decoder.decode(uint8Array);
        this.message = message
      } catch {}
    } else if (typeof error.response?.data === 'string') {
      this.message = error.response?.data
    }

    this.name = "EkonError";
  }
}

export class LoginError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'error.login.userPass';
    this.name = "LoginError";
  }
}

export class ValidationError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'Error de validació';
    try {
      const errorFields = Object.keys(error.response?.data?.errors);
      if (errorFields.length > 0) {
        this.message = `Error de validació ${errorFields.reduce((prev, current) => {
          return `${prev} [${current}]`;
        }, '')}.`;
      }
    } catch {}

    this.name = "ValidationError";
  }
}


export class UnprocessableEntityError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = `order.${error.response?.data}`;
    this.name = "UnprocessableEntityError";
  }
}

export class ConflictError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = error.response?.data;
    this.name = "ConflictError";
  }
}

export class GenericError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    if (typeof error.response?.data === 'string') {
      this.message = error.response?.data
    }
    this.name = "GenericError";
  }
}

export class TelevisionNotExistsError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'television.not.exist'
    this.name = "TelevisionNotExistsError";
  }
}

export class ForbiddenError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'error.forbidden'
    this.name = "ForbiddenError";
  }
}

export class MissingIncinerationAuthorizationError extends Error {
  constructor(error: AxiosError) {
    super(error.message);
    this.message = 'error.incineration.authorization'
    this.name = "MissingIncinerationAuthorizationError";
  }
}


