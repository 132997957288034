import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import { MissingIncinerationAuthorizationError, UnprocessableEntityError } from 'config/apiErrors';
import { StatusCodes } from 'models/OrderStatus';
import OrderType from 'models/OrderType';
import { ChangeStatusFormProps } from 'models/Workorder';

import ChangeStatus from '../ChangeStatus/index';

type FinishOrderProps = {
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  order: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FinishOrder = ({
  show, onHide, onSuccess, order,
}: FinishOrderProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const sendFinishOrder = (
    params: ChangeStatusFormProps,
    actions: FormikHelpers<ChangeStatusFormProps>,
  ) => {
    setLoading(true);
    config.apiFunus.workOrder
      .changeStatus(order.id, {
        ...params,
        changeState: true,
        infoQr: order.infoQr || {},
      })
      .then(() => {
        setLoading(false);
        showSuccessToast(t('order.finished'));
        onSuccess();
        return true;
      })
      .catch((error) => {
        setLoading(false);
        if (error instanceof UnprocessableEntityError) {
          showErrorToast(t(error.message));
        } else if (error instanceof MissingIncinerationAuthorizationError) {
          showErrorToast(t(error.message));
        } else {
          showErrorToast(t('order.notFinished'));
        }
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      className="workorder-card-modal"
      show={show}
      showCancel={false}
      title={t('order.finishOrder')}
      onHide={onHide}
    >
      {loading && <Loader />}
      <ChangeStatus
        loading={false}
        order={order}
        showComment={order.status === StatusCodes.IN_PROGRESS}
        showDeposit={
          order.status === StatusCodes.IN_PROGRESS
          && (order.type === OrderType.PICKUP
            || (order.type === OrderType.RECEPTION && !order?.infoQr?.coffinQr))
          }
        showSecondQr={
            order.status === StatusCodes.IN_PROGRESS
            && order.type === OrderType.PREPARATION
          }
        showVigil={
            order.status === StatusCodes.IN_PROGRESS
            && order.type === OrderType.PREPARATION
          }
        onSubmit={sendFinishOrder}
      />
    </Modal>
  );
};
