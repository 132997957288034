import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import Input, { InputProps } from 'components/base/Input';
import Select from 'components/base/Select';

import './index.scss';

export type OptionsType = {
  label: string;
  value: string;
};

export type SelectWithInputProps = Pick<
  InputProps,
  Exclude<keyof InputProps, 'onChange'>
> & {
  className?: string;
  errors?: {
    [key: string]: string | boolean | undefined;
  };
  onChange(key: string, value: string | null | undefined): void;
  options: OptionsType[];
  values: {
    [key: string]: string | undefined;
  };
};

const SelectWithInput: FC<SelectWithInputProps> = ({
  className,
  disabled,
  errors,
  name,
  onChange,
  onClick,
  onFocus,
  options,
  placeholder,
  values,
}) => {
  const [selected, setSelected] = useState<OptionsType>();

  useEffect(() => {
    if (options?.length) {
      const [newSelected] = options;
      setSelected(newSelected);
    }
  }, [options]);

  useEffect(() => {
    let newSelected;
    if (values && options?.length) {
      const selectedKey = Object.keys(values).find((key) => !!values[key]);
      if (!selected && selectedKey) {
        newSelected = options.find((opt) => opt.value === selectedKey);
      }
      if (newSelected) {
        setSelected(newSelected);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, values]);

  const onChangeSelect = (newSelected: OptionsType) => {
    if (newSelected.value !== selected?.value) {
      options.forEach(({ value }) => onChange(value, undefined));
      setSelected(newSelected);
    }
  };

  const change = (val: string) => {
    if (selected) {
      onChange(selected.value, val);
    }
  };

  return (
    <div className={classNames('funus-select-input', className)}>
      <Select<OptionsType>
        disabled={disabled}
        getLabel={({ label }) => label}
        getValue={({ value }) => value}
        name="select-input"
        options={options}
        placeholder={placeholder}
        value={selected}
        onChange={onChangeSelect}
      />
      <Input
        disabled={disabled || !selected}
        error={selected?.value ? errors?.[selected?.value] : undefined}
        id={name}
        name={name}
        placeholder={selected?.label || placeholder}
        value={selected?.value ? values[selected?.value] || '' : ''}
        onChange={change}
        onClick={onClick}
        onFocus={onFocus}
      />
    </div>
  );
};

export default SelectWithInput;
