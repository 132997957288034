import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCountries } from 'actions/masterData';

import { IMasterDataDto } from 'models/MasterData';
import { RootState } from 'store';

export interface CountriesHook {
  countries: IMasterDataDto[],
  getCountry: (code: string | undefined) => IMasterDataDto | undefined
}

export const useCountries = ():CountriesHook => {
  const dispatch = useDispatch();
  const { items, normalized } = useSelector(
    (state: RootState) => ({
      items: state.masterData.countries,
      normalized: state.normalized.countries,
    }),
  );

  const getCountry = useCallback(
    (code: string | undefined) => (code && normalized[code]
      ? normalized[code]
      : undefined),
    [normalized],
  );

  useEffect(() => {
    dispatch(getCountries());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countries: items || [],
    getCountry,
  };
};
