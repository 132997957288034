import { FormikProps, getIn } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEuro } from 'react-icons/bi';
import { useSelector } from 'react-redux';

import { normalizeEntity } from 'utils/normalizer';

import Input from 'components/base/Input';
import Select from 'components/base/Select';

import { NormalizedEntity } from 'models/MasterService';
import { InsuranceCompany, LocalData, SaveRecord } from 'models/Record';
import { RootState } from 'store';

type InsuranceFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const InsuranceFields: FC<InsuranceFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values, setValues,
  },
}) => {
  const { insurances } = useSelector((state: RootState) => state.masterData);
  const { normalized } = useSelector((state: RootState) => state);
  const [locals, setLocals] = useState<LocalData[]>([]);
  const [normalizedLocals, setNormalizedLocals] = useState<
    NormalizedEntity<LocalData>
  >({});
  const { t } = useTranslation();

  useEffect(() => {
    if (
      values?.insuranceData?.companyName
      && normalized.insurances[values.insuranceData.companyName]
    ) {
      setNormalizedLocals(
        normalizeEntity<LocalData>(
          normalized.insurances[values.insuranceData.companyName]?.locals || [],
          'localId',
        ),
      );
      setLocals(
        normalized.insurances[values.insuranceData.companyName]?.locals || [],
      );
    } else {
      setLocals([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.insuranceData?.companyName]);
  return (
    <fieldset>
      {createMode && <legend>{t('record.steps.insurance')}</legend>}
      <Input
        checked={values.insuranceData?.hasPolicy}
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'insuranceData.hasPolicy')}
        name="insuranceData.hasPolicy"
        placeholder={t('record.insuranceData.hasPolicy')}
        type="checkbox"
        onBlur={handleBlur}
        onChange={(hasPolicy) => {
          setValues({
            ...values,
            insuranceData: {
              hasPolicy: !!hasPolicy,
            },
          });
        }}
      />
      <Select<InsuranceCompany>
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.companyName')}
        getLabel={({ description }) => description}
        getValue={({ code }) => code}
        name="insuranceData.companyName"
        options={insurances}
        placeholder={t('record.insuranceData.companyName')}
        value={
          values.insuranceData?.companyName
            ? normalized.insurances[values.insuranceData?.companyName]
            : undefined
        }
        searchable
        onBlur={handleBlur}
        onChange={(val) => {
          setFieldValue('insuranceData.companyName', val?.code);
          setFieldValue(
            'insuranceData.idPaymentLocation',
            val?.locals?.length === 1 ? val.locals[0].localId : undefined,
          );
          setNormalizedLocals(
            normalizeEntity<LocalData>(val?.locals || [], 'localId'),
          );
        }}
      />
      <Select<LocalData>
        disabled={
          disabled || isSubmitting || !values.insuranceData?.companyName
        }
        error={getIn(errors, 'insuranceData.idPaymentLocation')}
        getLabel={({ description }) => description}
        getValue={({ localId }) => localId}
        name="insuranceData.idPaymentLocation"
        options={locals}
        placeholder={t('record.insuranceData.idPaymentLocation')}
        value={
          values.insuranceData?.idPaymentLocation
            ? normalizedLocals[values.insuranceData.idPaymentLocation]
            : undefined
        }
        searchable
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.idPaymentLocation', val?.localId)}
      />
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.policyNumber')}
        maxLength={20}
        name="insuranceData.policyNumber"
        placeholder={t('record.insuranceData.policyNumber')}
        type="text"
        value={values.insuranceData?.policyNumber || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.policyNumber', val)}
      />
      {!createMode && (
        <Input
          checked={values.insuranceData?.renounce}
          disabled={
            disabled || isSubmitting || !values.insuranceData?.hasPolicy
          }
          error={getIn(errors, 'insuranceData.renounce')}
          name="insuranceData.renounce"
          placeholder={t('record.insuranceData.renounce')}
          type="checkbox"
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('insuranceData.renounce', val)}
        />
      )}
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.amount')}
        name="insuranceData.amount"
        placeholder={t('record.insuranceData.amount')}
        value={values.insuranceData?.amount || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.amount', val)}
      />
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.agent')}
        name="insuranceData.agent"
        placeholder={t('record.insuranceData.agent')}
        type="text"
        value={values.insuranceData?.agent || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.agent', val)}
      />
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.maxCoverage')}
        id="insuranceData.maxCoverage"
        name="insuranceData.maxCoverage"
        placeholder={t('record.insuranceData.maxCoverage')}
        rightAddon={(<BiEuro />)}
        type="number"
        value={values.insuranceData?.maxCoverage || ''}
        onBlur={handleBlur}
        onChange={(val) => (val
          ? setFieldValue('insuranceData.maxCoverage', val)
          : setFieldValue('insuranceData.maxCoverage', undefined))}
      />
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.companyDelegation')}
        name="insuranceData.companyDelegation"
        placeholder={t('record.insuranceData.companyDelegation')}
        type="text"
        value={values.insuranceData?.companyDelegation || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.companyDelegation', val)}
      />
      <Input
        disabled={disabled || isSubmitting || !values.insuranceData?.hasPolicy}
        error={getIn(errors, 'insuranceData.sinister')}
        name="insuranceData.sinister"
        placeholder={t('record.insuranceData.sinister')}
        type="text"
        value={values.insuranceData?.sinister || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('insuranceData.sinister', val)}
      />
    </fieldset>
  );
};

export default InsuranceFields;
