import classNames from 'classnames';
import React, {
  createRef, ChangeEvent, FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import defaultImage from 'assets/images/defaultImage.png';

import { checkSize, maxAllowedInKb } from 'utils/checkFileSize';
import { showErrorToast } from 'utils/toasts';

import './index.scss';
import Button from 'components/base/Button';

const matchUrlExpression = /^data:image\/(png|jpg|jpeg);base64,/;

type UploadProps = {
  disabled?: boolean;
  maxSize?: number;
  name: string;
  onChange(preview: string | null | undefined): void;
  placeholder?: string;
  value?: string;
  layout?: UploadLayouts
  className?: string;
  placeholderImage?: string;
};

export enum UploadLayouts {
  PROFILE = 'PROFILE',
  DISAGREEMENT = 'DISAGREEMENT'
}

const Upload: FC<UploadProps> = ({
  disabled,
  maxSize = maxAllowedInKb,
  name,
  onChange,
  placeholder,
  value,
  layout = UploadLayouts.PROFILE,
  className,
  placeholderImage = defaultImage,
}) => {
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState<string>('');
  const [base64, setBase64] = useState<string | null>();
  const fileInputRef = createRef<HTMLInputElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(base64), [base64]);

  useEffect(() => {
    if (value) {
      if (!value.match(matchUrlExpression)) {
        setImagePreview(`data:image/png;base64,${value}`);
      }
    } else {
      setImagePreview('');
    }
  }, [value]);

  const openWindow = () => {
    if (!disabled && fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const photoUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file: File | null = e?.target?.files?.length
      ? (e.target.files[0] as File)
      : null;

    if (
      reader !== undefined
      && file !== undefined
      && file
      && checkSize(file, errorSize, maxSize)
    ) {
      reader.onloadend = async () => {
        setImagePreview(reader.result as string);
        setBase64((reader.result as string).replace(matchUrlExpression, ''));
      };

      reader.readAsDataURL(file);
    }
    (e.target as HTMLInputElement).value = '';
  };

  const remove = () => {
    setImagePreview('');
    setBase64(null);
  };

  const errorSize = () => {
    showErrorToast(
      `${t('user.errorFileSize')} ${maxSize}Kb. ${t('user.reduceFileSize')}`,
    );
  };

  return (
    <div
      className={classNames(
        className,
        layout === UploadLayouts.PROFILE ? 'image-input' : '',
        layout === UploadLayouts.DISAGREEMENT ? 'disagreement-layout' : '',
      )}
    >
      <div className="buttons">
        <Button
          className="open-button"
          color="secondary"
          disabled={disabled}
          text={placeholder}
          onClick={openWindow}
        />
        <input
          ref={fileInputRef}
          accept=".jpeg, .png, .jpg"
          disabled={disabled}
          id="file-upload"
          name={name}
          src={imagePreview}
          type="file"
          onChange={photoUpload}
        />
        {imagePreview && (
        <Button
          color="secondary"
          disabled={disabled}
          text={t('common.remove')}
          onClick={remove}
        />
        )}
      </div>
      <Button
        className="display-container"
        color="transparent"
        onClick={openWindow}
      >
        <span>
          <img
            alt="Add icon"
            className="image"
            src={imagePreview || placeholderImage}
          />
        </span>
      </Button>
    </div>
  );
};

export default Upload;
